.user-form-container {
    padding: 20px 20px 0px 20px ;
    min-height: 400px;
}


.site-layout-content-edit-user-screen {
    max-width: 650px;
    margin: auto;

}

#edit-user-form .ant-btn {
    margin-right: 8px;
  }