.ant-layout-header {
    border-radius: 0px 0px 50px 50px;
    /* border-radius: 0px 0px 20px 20px; */
    position: fixed;
    width: 100%;
    z-index: 100;
  
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.4);
  }
  
  
  .ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    box-shadow: 0px 0px 15px 0 rgba(24 ,144 ,255 ,.4);
  }
  
  .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover { 
    transition: width 10s !important;
  } 

.main-menu {

  background: transparent !important;

  
}
.ant-layout-header {

  /* background-image: linear-gradient(to right, #FF8008 0%, #FFC837  51%, #FF8008  100%) !important; */

  /* background: #0f0c29; 
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29); 
  
   */

   



}

.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
   border-radius: 2px;
   /* margin-top: 10px; */


  /* background: #f12711; 
  background: -webkit-linear-gradient(to right, #f5af19, #f12711); 
  background: linear-gradient(to right, #f5af19, #f12711);  */
  
}