.layout {
  min-height: 100vh !important;
  padding-bottom: 100px !important;
  /* background-image: linear-gradient( 135deg, #3C8CE7 10%, #00EAFF 100%); */
}
.main-screen-content {
  padding: 0 50px;
  margin-top: 85px;
  /* margin-top: 85px; */
}

.addUser-container {
  /* float: right; */
  /* height: 30px; */
  padding-right: 5px;
  padding-bottom: 15px;
}
