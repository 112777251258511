

.site-layout-content {
  /* min-height: 280px !important; */
  /* margin-top: 20px !important; */
  padding-bottom: 0px !important;
  background: #fff !important;
  border-radius: 20px !important;

  overflow: hidden;
}

.ant-table-pagination {
  padding-right: 20px !important;
}