.loginFormContainer {
  display: table;
  margin: 0 auto;
  margin-top: 20vh;
  padding-top: 20px;

  margin: auto;
  width: 50%;

  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);

  min-height: 200px;
  max-width: 550px;

  width: 100%;

  margin: 100px auto;
  /* background: #ffffff; */
  background: rgba(255, 255, 255, 0.95);
  /* backdrop-filter: blur(1px); */


  
  border-radius: 15px;
}

.loginFormTitle {
  text-align: center;
  padding-bottom: 10px;
}

.loginScreen {

  /* background-color: #4158D0;
  background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%) !important; */

  background: #485563;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #29323c, #485563);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #29323c, #485563); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  
  min-height: 100vh;
}

